import Vue from 'vue'
import Vuex from 'vuex'
import Pusher from 'pusher-js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error:false,
    pusher_koth:new Pusher(
        'cbf4106ddfd7612cf940',
        {
          cluster: 'eu',
          encrypted: true,
          authEndpoint: process.env.VUE_APP_API_KOTH + 'broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              Accept: "application/json"
            }
          },
        }),
    pusher_crisis:new Pusher(
        'cbf4106ddfd7612cf940',
        {
          cluster: 'eu',
          encrypted: true,
          authEndpoint: process.env.VUE_APP_URL_API+ 'broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              Accept: "application/json"
            }
          },
        }),
    user : {
      score: 0,
      username:'',
      avatar:''
    },
    company:{
        id:'',
        logo:''
    },
    team :{
        id:'',
        score:0
    },
      badge :{
          id:'',
      },
    comp_team:{
      rank:0,
      score:0
    }
  },
  mutations: { //sync
    user(state,user){
      state.user=user;
    },
    team(state,team){
      state.team=team;
    },
    badge(state,badge){
      state.badge=badge;
    },
    company(state,company){
      state.company=company;
    },
    //store.commit('incrementScore',30)
    incrementScore(state,pts){
      state.user.score+=pts;
    },
    interceptError(state,value){
      state.error = value;
    },
    refreshAvatar(state,avatar){
      state.user.avatar=avatar;
    },
    refreshTeamAvatar(state,avatar){
      state.team.avatar=avatar;
    },
      refreshCompanyLogo(state,logo){
      state.company.logo=logo;
    },

    updateTeam(state,{ rank,score }){
          state.comp_team.rank = rank;
          state.comp_team.score = score;
    },
    updatePusher(state, token) {
      if (state.pusher_koth.config.auth && state.pusher_koth.config.auth) {
        state.pusher_koth.config.auth.headers['Authorization'] = 'Bearer ' + token;
      } else {
        console.error('pusher_koth auth or headers is undefined');
      }

      if (state.pusher_crisis.config.auth && state.pusher_crisis.config.auth) {
        state.pusher_crisis.config.auth.headers['Authorization'] = 'Bearer ' + token;
      } else {
        console.error('pusher_crisis auth or headers is undefined');
      }
    }
  },
  actions: { //async
  },
  getters:{

  },
  modules: {
  }
})

